"use client";

import { useTranslations } from "next-intl";
import { memo } from "react";

import { ReviewGrid } from "@/components/organisms/Reviews/Reviews";

const TestimonialSection = () => {
  const t = useTranslations("home");

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto max-w-4xl lg:text-center">
          <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            {t("testimonials")}
          </h2>
          <p className="mt-6 text-lg/8 text-gray-600">{t("testimonials_description")}</p>
        </div>
        <ReviewGrid />
      </div>
    </div>
  );
};

export default memo(TestimonialSection);
