"use client";

import { Button } from "@nextui-org/react";
import { FormikHelpers, useFormik } from "formik";
import { useLocale, useTranslations } from "next-intl";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Bounce, toast } from "react-toastify";
import { object, string } from "yup";

import { Icons, Input } from "@/components/atoms";
import { CONTACT_EMAIL, CONTACT_PHONE, RECAPTCHA_SITE_KEY } from "@/configs/environment";
import { post } from "@/utils/fetcher";

type ContactFormType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  content: string;
  token: string;
};

const initialValues: ContactFormType = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  content: "",
  token: "",
};

const ContactSection = () => {
  const t = useTranslations();
  const locale = useLocale();
  const [loading, setLoading] = useState<boolean>(false);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  /**
   * handleSubmitForm
   * @param values ContactFormType
   */
  const handleSubmitForm = useCallback(
    async (values: ContactFormType, formikHelpers: FormikHelpers<ContactFormType>) => {
      setLoading(true);
      const data = await post<string>(`/api/${locale}/contact`, values);
      formikHelpers.setFieldValue("token", "");
      setLoading(false);
      recaptchaRef.current?.reset();

      if (data?.type === "success") {
        toast.success(data?.result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        toast.error(data?.result, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    },
    [locale, setLoading]
  );

  const validationSchema = useMemo(
    () =>
      object({
        firstName: string()
          .required(t("messages.required", { label: t("common.first_name") }))
          .max(50, t("messages.max_length", { max: 50 })),
        lastName: string()
          .required(t("messages.required", { label: t("common.last_name") }))
          .max(50, t("messages.max_length", { max: 50 })),
        email: string()
          .email(t("messages.invalid_email"))
          .required(t("messages.required", { label: t("common.email") }))
          .max(200, t("messages.max_length", { max: 200 })),
        phoneNumber: string()
          .matches(/^\d+$/, t("messages.invalid_phone"))
          .max(15, t("messages.max_length", { max: 15 })),
        content: string()
          .required(t("messages.required", { label: t("common.content") }))
          .max(1000, t("messages.max_length", { max: 1000 })),
        token: string().required(t("messages.required", { label: t("common.captcha") })),
      }),
    [t]
  );

  const { setFieldValue, touched, errors, handleChange, handleBlur, handleSubmit, values } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
  });

  /**
   * handle captcha change
   * @param token string
   */
  const handleCaptchaChange = useCallback(
    (token: string | null) => {
      // Set the captcha token when the user completes the reCAPTCHA
      if (token) {
        setFieldValue("token", token);
      }
    },
    [setFieldValue]
  );

  return (
    <div className="py-24 sm:py-32" id="contactForm">
      <div className="mx-auto grid max-w-7xl grid-cols-1 px-6 lg:grid-cols-2">
        <div className="bg-white px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              {t("home.get_in_touch")}
            </h2>
            <p className="mt-6 text-lg/8 text-gray-600">{t("home.contact_message")}</p>
            <dl className="mt-10 space-y-4 text-base/7 text-gray-600">
              <div className="flex items-center gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <Icons.Address aria-hidden="true" className="h-7 w-6 text-gray-400" />
                </dt>
                <dd>{t("home.address")}</dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <Icons.Phone aria-hidden="true" className="h-6 w-6 text-gray-400" />
                </dt>
                <dd>
                  <a href={`tel:${CONTACT_PHONE}`} className="hover:text-gray-900">
                    {CONTACT_PHONE}
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <Icons.Email aria-hidden="true" className="h-7 w-6 text-gray-400" />
                </dt>
                <dd>
                  <a href={`mailto:${CONTACT_EMAIL}`} className="hover:text-gray-900">
                    {CONTACT_EMAIL}
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="bg-white px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 sm:gap-y-0">
              <div>
                <Input
                  disabled={loading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  autoComplete="given-name"
                  label={t("common.first_name")}
                  required
                  maxLength={50}
                  error={touched.firstName ? errors.firstName : ""}
                />
              </div>
              <div>
                <Input
                  disabled={loading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  autoComplete="family-name"
                  required
                  maxLength={50}
                  error={touched.lastName ? errors.lastName : ""}
                  label={t("common.last_name")}
                />
              </div>
              <div className="sm:col-span-2">
                <Input
                  disabled={loading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="email"
                  name="email"
                  type="email"
                  value={values.email}
                  required
                  maxLength={200}
                  error={touched.email ? errors.email : ""}
                  autoComplete="email"
                  label={t("common.email")}
                />
              </div>
              <div className="sm:col-span-2">
                <Input
                  disabled={loading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  value={values.phoneNumber}
                  autoComplete="tel"
                  max={15}
                  error={touched.phoneNumber ? errors.phoneNumber : ""}
                  label={t("common.phone_number")}
                />
              </div>
              <div className="sm:col-span-2">
                <Input
                  disabled={loading}
                  name="content"
                  id="content"
                  label={t("common.content")}
                  required
                  multiline
                  error={touched.content ? errors.content : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={4}
                  maxLength={1000}
                  defaultValue={values.content}
                />
              </div>
              <div className="sm:col-span-2">
                <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={handleCaptchaChange} />
                <p className="mt-2 block min-h-[24px] text-xs text-red-600">
                  {!errors.lastName &&
                    !errors.firstName &&
                    !errors.email &&
                    !errors.phoneNumber &&
                    !errors.content &&
                    errors.token}
                </p>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <Button type="submit" color="primary" radius="none" isLoading={loading}>
                {t("common.send")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default memo(ContactSection);
