import { useTranslations } from "next-intl";

import { Icons } from "@/components/atoms";

const navigation = [
  {
    name: "EnvelopeIcon ",
    href: "mailto:dttrung.code@gmail.com",
    icon: Icons.Email,
  },
  {
    name: "Phone",
    href: "tel: (+84) 384342451",
    icon: Icons.Phone,
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/trungduong.dev",
    icon: Icons.Facebook,
  },
];

export default function Footer() {
  const t = useTranslations("footer");

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between">
        <div className="flex justify-center gap-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-600 hover:text-gray-800">
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" className="size-6" />
            </a>
          ))}
        </div>
        <p className="mt-8 text-center text-sm/6 text-gray-600 md:order-1 md:mt-0">{t("copyright")}</p>
      </div>
    </footer>
  );
}
