"use client";

import Image from "next/image";
import { useTranslations } from "next-intl";
import { memo } from "react";

const projects = [
  {
    name: "TMS",
    width: 1920,
    height: 955,
    image: "/images/autotms.png",
    url: "https://www.autotms.vn",
  },
  {
    name: "JOT",
    width: 1221,
    height: 760,
    image: "/images/jotnw.jpg",
    url: "https://www2.jotnw.or.jp",
  },
  {
    name: "BKZ",
    width: 423,
    height: 264,
    image: "/images/hankyou-banner.jpg",
    url: "https://www.am-bition.jp/rac-tech/jidounyuryoku",
  },
  {
    name: "ZOO",
    width: 956,
    height: 615,
    image: "/images/tokyo-zoo.jpg",
    url: "https://www.tokyo-zoo.net",
  },
  {
    name: "KENSHOKU-FORUM",
    width: 1402,
    height: 945,
    image: "/images/kenshoku-forum.jpg",
    url: "https://www.kenshoku-forum.jp",
  },
  {
    name: "MYGATE",
    width: 1576,
    height: 892,
    image: "/images/mygate.jpg",
    url: "http://mygate.jp",
  },
];

const PortfolioSection = () => {
  const t = useTranslations("home");
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto max-w-4xl lg:text-center">
          <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            {t("projects_clients")}
          </h2>
          <p className="mt-6 text-lg/8 text-gray-600">{t("projects_clients_description")}</p>
        </div>
        <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {projects.map((item, index) => (
              <Image
                key={index}
                width={item.width}
                height={item.height}
                alt={item.name}
                src={item.image}
                className="h-full w-full rounded-md border-2 border-gray-200 shadow-md hover:opacity-75"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PortfolioSection);
