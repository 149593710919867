import { Button, Navbar, NavbarBrand, NavbarContent, NavbarItem } from "@nextui-org/react";
import { useTranslations } from "next-intl";
import { useCallback } from "react";

import { Icons } from "@/components/atoms";
import { LocaleSwitcher } from "@/components/organisms";

export default function Header() {
  const t = useTranslations("header");

  const handleContactClick = useCallback(() => {
    const element = document.getElementById("contactForm");
    element?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Navbar maxWidth="xl">
      <NavbarBrand>
        <Icons.Logo height={30} />
      </NavbarBrand>
      <NavbarContent className="hidden gap-6 sm:flex" justify="center">
        {/* <NavbarItem>
          <Link color="foreground" href="#">
            {t("services")}
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="#">
            {t("about")}
          </Link>
        </NavbarItem> */}
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <LocaleSwitcher />
        </NavbarItem>
        <NavbarItem className="hidden lg:flex">
          <Button color="primary" radius="full" className="px-8" onClick={handleContactClick}>
            {t("contact")}
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}
