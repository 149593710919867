"use client";

import { useTranslations } from "next-intl";
import { memo } from "react";

const items = [
  {
    company: "can_tho_university",
    time: "08/2015 - 12/2019",
    certificate: "university.title",
    description: "university.description",
  },
];

const EducationSection = () => {
  const t = useTranslations("home");

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto max-w-4xl lg:text-center">
          <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            {t("education")}
          </h2>
          <p className="mt-6 text-lg/8 text-gray-600">{t("education_description")}</p>
        </div>
        <div className="mx-auto mt-16 bg-white px-8 py-12 sm:mt-20 lg:mt-24">
          <ul role="list" className="divide-y divide-gray-200">
            {items.map((item, index) => (
              <li key={index} className="py-4">
                <div className="grid grid-cols-1 gap-2 md:grid-cols-6">
                  <div className="col-span-1 flex flex-col md:col-span-2">
                    <p className="text-lg/8 font-semibold text-gray-900">{t(item.company)}</p>
                    <p className="mt-1 text-lg/8 text-blue-600">{item.time}</p>
                  </div>
                  <div className="col-span-1 md:col-span-4">
                    <p className="text-lg/8 font-semibold text-gray-900">{t(item.certificate)}</p>
                    <p className="mt-1 text-base/7 text-gray-600">{t(item.description)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(EducationSection);
