import { AnyObject } from "@/types";
import { ResponseType } from "@/types/fetcher";

/**
 * Perform a POST request to the specified URL with the given parameters and return the response data.
 *
 * @param url - The URL to send the POST request to.
 * @param params - An object containing the parameters to include in the request body.
 * @param config - Configuration for Axios request.
 * @returns The response data from the POST request.
 */
export const post = <T>(
  url: string,
  params?: AnyObject,
  config?: Omit<HeadersInit, "body">
): Promise<ResponseType<T>> => {
  return new Promise<ResponseType<T>>((resolve) => {
    fetch(url, {
      method: "POST",
      ...config,
      body: JSON.stringify(params),
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          return resolve({
            result: data?.result as T,
            type: data?.type,
            code: response.status,
          });
        }

        return resolve({
          result: response.statusText as T,
          error: response.statusText,
          type: "error",
          code: response.status,
        });
      })
      .catch((error) => {
        console.log("error", error);
        resolve({
          error: error,
          type: "error",
          code: 500,
        });
      });
  });
};
