"use client";

import { useTranslations } from "next-intl";
import { memo } from "react";

import { Icons } from "@/components/atoms";

const techStack = [
  {
    name: "JavaScript",
    icon: Icons.JavaScript,
  },
  {
    name: "TypeScript",
    icon: Icons.TypeScript,
  },
  {
    name: "React",
    icon: Icons.React,
  },
  {
    name: "Next.js",
    icon: Icons.NextJS,
  },
  {
    name: "Node.js",
    icon: Icons.NodeJS,
  },
  {
    name: "Nest.js",
    icon: Icons.NestJS,
  },
  {
    name: "Tailwindcss",
    icon: Icons.Tailwindcss,
  },
  {
    name: "Redux",
    icon: Icons.Redux,
  },
  {
    name: "MySQL",
    icon: Icons.MySQL,
  },
  {
    name: "Strapi",
    icon: Icons.Strapi,
  },
  {
    name: "Storybook",
    icon: Icons.Storybook,
  },
  {
    name: "Socket.io",
    icon: Icons.SocketIO,
  },
  {
    name: "Sass/Scss",
    icon: Icons.Sass,
  },
  {
    name: "Jest",
    icon: Icons.Jest,
  },
  {
    name: "Graphql",
    icon: Icons.Graphql,
  },
  {
    name: "Robot Framework",
    icon: Icons.Robotframework,
  },
  {
    name: "Vite",
    icon: Icons.Vite,
  },
  {
    name: "Figma",
    icon: Icons.Figma,
  },
  {
    name: "MongoDB",
    icon: Icons.MongoDB,
  },
  {
    name: "jQuery",
    icon: Icons.jQuery,
  },
  {
    name: "Jenkins",
    icon: Icons.Jenkins,
  },
  {
    name: "Docker",
    icon: Icons.Docker,
  },
  {
    name: "Nginx",
    icon: Icons.Nginx,
  },
  {
    name: "Git",
    icon: Icons.Git,
  },
];

const TechStackSection = () => {
  const t = useTranslations("home");

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto max-w-3xl lg:text-center">
          <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            {t("tech_stack")}
          </h2>
          <p className="mt-6 text-lg/8 text-gray-600">{t("tech_stack_description")}</p>
        </div>
        <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
          <dl className="grid grid-cols-3 gap-6 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
            {techStack.map((item) => (
              <div key={item.name} className="flex flex-col">
                <dt className="flex flex-col items-center justify-center gap-3 text-center text-base/7 font-semibold text-gray-900">
                  {item.icon && <item.icon aria-hidden="true" className="size-16" />}
                  {item.name}
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default memo(TechStackSection);
