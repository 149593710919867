"use client";

import { useTranslations } from "next-intl";
import { memo } from "react";

import { Icons } from "@/components/atoms";

const features = [
  {
    name: "web_development",
    description: "web_development_description",
    icon: Icons.WebDevelopment,
  },
  {
    name: "ui_outsourcing_implementation",
    description: "ui_outsourcing_implementation_description",
    icon: Icons.Outsourcing,
  },
  {
    name: "digital_transformation",
    description: "digital_transformation_description",
    icon: Icons.DigitalTransformation,
  },
  {
    name: "domain_web_deployment",
    description: "domain_web_deployment_description",
    icon: Icons.DomainWebDeployment,
  },
  {
    name: "performance_optimization",
    description: "performance_optimization_description",
    icon: Icons.PerformanceOptimization,
  },
  {
    name: "technical_consulting",
    description: "technical_consulting_description",
    icon: Icons.TechnicalConsulting,
  },
];

const FeatureSection = () => {
  const t = useTranslations("home");

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto max-w-3xl lg:text-center">
          <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            {t("my_services")}
          </h2>
          <p className="mt-6 text-lg/8 text-gray-600">{t("my_services_description")}</p>
        </div>
        <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
          <dl className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col bg-white px-8 py-12">
                <dt className="flex flex-col items-center justify-center gap-3 text-base/7 font-semibold text-gray-900">
                  {feature.icon && <feature.icon aria-hidden="true" className="size-16" />}
                  {t(feature.name)}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-600">
                  <p className="flex-auto text-center">{t(feature.description)}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default memo(FeatureSection);
