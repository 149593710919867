export { default as CareerJourneySection } from "./CareerJourneySection/CareerJourneySection";
export { default as ContactSection } from "./ContactSection/ContactSection";
export { default as EducationSection } from "./EducationSection/EducationSection";
export { default as FeatureSection } from "./FeatureSection/FeatureSection";
export { default as Footer } from "./Footer/Footer";
export { default as Header } from "./Header/Header";
export { default as HeroSection } from "./HeroSection/HeroSection";
export { default as LocaleSwitcher } from "./LocaleSwitcher/LocaleSwitcher";
export { default as PortfolioSection } from "./PortfolioSection/PortfolioSection";
export { default as TechStackSection } from "./TechStackSection/TechStackSection";
export { default as TestimonialSection } from "./TestimonialSection/TestimonialSection";
export { default as WorkExperienceSection } from "./WorkExperienceSection/WorkExperienceSection";
