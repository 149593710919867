import clsx from "clsx";
import { ChangeEvent, forwardRef, HTMLAttributes, InputHTMLAttributes, ReactNode } from "react";

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "step"> & {
  label?: string;
  description?: ReactNode;
  hint?: string;
  multiline?: boolean;
  rows?: number;
  showError?: boolean;
  error?: string;
  onChange?: (_event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  endAdornment?: React.ReactNode;
};

function Input(
  {
    id,
    name,
    label,
    hint,
    rows = 4,
    required,
    description,
    type = "text",
    multiline = false,
    className,
    showError = true,
    error = "",
    disabled,
    onChange,
    endAdornment,
    ...props
  }: InputProps,
  ref?: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement | null>
) {
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={id} className="block break-all text-sm font-bold leading-6 text-gray-900">
          {label}
          {required && <span className="text-sm text-red-600"> *</span>}
        </label>
      )}
      {description && (
        <p
          className={clsx("text-xs leading-6 text-gray-600", {
            "mt-1": label,
          })}
        >
          {description}
        </p>
      )}
      {hint && <p className="text-end text-sm leading-6 text-gray-500">{hint}</p>}
      <div
        className={clsx({
          "mt-2": label || description || hint,
          "relative rounded-md shadow-sm": endAdornment,
        })}
      >
        {multiline ? (
          <textarea
            id={id}
            rows={rows}
            name={name}
            disabled={disabled}
            onChange={onChange}
            ref={ref as React.ForwardedRef<HTMLTextAreaElement | null>}
            className={clsx(
              "block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600",
              {
                "ring-gray-300 focus:ring-inset": !showError || !error,
                "ring-red-300 focus:ring-red-500": showError && error,
                "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200":
                  disabled,
              },
              className
            )}
            {...(props as HTMLAttributes<HTMLTextAreaElement>)}
          />
        ) : (
          <input
            id={id}
            type={type}
            name={name}
            disabled={disabled}
            ref={ref as React.ForwardedRef<HTMLInputElement | null>}
            onChange={onChange}
            className={clsx(
              "block w-full rounded-md bg-white px-3.5 py-2 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600",
              {
                "ring-gray-300 focus:ring-inset": !showError || !error,
                "ring-red-300 focus:ring-red-500": showError && error,
                "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200":
                  disabled,
              },
              className
            )}
            {...props}
          />
        )}
        {endAdornment && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">{endAdornment}</div>
        )}
      </div>
      {showError && <p className="mt-2 block min-h-[24px] text-xs text-red-600">{error}</p>}
    </div>
  );
}

export default forwardRef(Input);
