"use client";

import { useTranslations } from "next-intl";
import { memo } from "react";

import { twClass } from "@/utils/common";

const items = [
  {
    company: "career.nec",
    time: "04/2025 - Present",
    certificate: "Front-end Developer",
    description: ["career.nec_description"],
  },
  {
    company: "career.martaskify",
    time: "12/2024 - 04/2025",
    certificate: "Front-end Developer",
    description: ["career.martaskify_description"],
  },
  {
    company: "career.gss",
    time: "05/2019 - 12/2024",
    certificate: "Software Developer",
    description: [
      "career.gss_description4",
      "career.gss_description3",
      "career.gss_description2",
      "career.gss_description1",
    ],
  },
];

const CareerJourneySection = () => {
  const t = useTranslations("home");

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto max-w-4xl lg:text-center">
          <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            {t("career_journey")}
          </h2>
          <p className="mt-6 text-lg/8 text-gray-600">{t("career_journey_description")}</p>
        </div>
        <div className="mx-auto mt-16 bg-white px-8 py-12 sm:mt-20 lg:mt-24">
          <ul role="list" className="divide-y divide-gray-200">
            {items.map((item, index) => (
              <li key={index} className="py-4">
                <div className="grid grid-cols-1 gap-2 md:grid-cols-6">
                  <div className="col-span-1 flex flex-col md:col-span-2">
                    <p className="text-lg/8 font-semibold text-gray-900">{t(item.company)}</p>
                    <p className="mt-1 text-lg/8 text-blue-600">{item.time}</p>
                  </div>
                  <div className="col-span-1 md:col-span-4">
                    <p className="text-lg/8 font-semibold text-gray-900">{item.certificate}</p>
                    <ul
                      role="list"
                      className={twClass({
                        "list-disc pl-4 marker:text-gray-300": item.description.length > 1,
                      })}
                    >
                      {item.description.map((subItem, index) => (
                        <li key={index} className="py-1">
                          {t.rich(subItem, {
                            strong: (chunks) => <strong className="text-gray-600">{chunks}</strong>,
                          })}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(CareerJourneySection);
