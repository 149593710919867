"use client";

import clsx from "clsx";
import { useInView } from "framer-motion";
import Image from "next/image";
import { useTranslations } from "next-intl";
import { useEffect, useMemo, useRef, useState } from "react";

import { Icons } from "@/components/atoms";
import { twClass } from "@/utils/common";

interface Review {
  title: string;
  body: string;
  author: string;
  rating: 1 | 2 | 3 | 4 | 5;
  role?: string;
  imageUrl?: string;
}

const reviews: Array<Review> = [
  {
    title: "mr_hieu.title",
    body: "mr_hieu.body",
    author: "mr_hieu.name",
    role: "mr_hieu.role",
    imageUrl: "/images/hieu-tran.jpg",
    rating: 5,
  },
  {
    title: "mr_loc.title",
    body: "mr_loc.body",
    author: "mr_loc.name",
    role: "mr_loc.role",
    imageUrl: "/images/loc-hoang.jpg",
    rating: 5,
  },
  {
    title: "mr_tam.title",
    body: "mr_tam.body",
    author: "mr_tam.name",
    role: "mr_tam.role",
    imageUrl: "/images/tam-hoang.jpg",
    rating: 5,
  },
  {
    title: "mr_nghia.title",
    body: "mr_nghia.body",
    author: "mr_nghia.name",
    role: "mr_nghia.role",
    imageUrl: "/images/nghia-tong.jpeg",
    rating: 5,
  },
  {
    title: "mr_hoan.title",
    body: "mr_hoan.body",
    author: "mr_hoan.name",
    role: "mr_hoan.role",
    imageUrl: "/images/hoan-nguyen.jpeg",
    rating: 5,
  },
  {
    title: "ms_dao.title",
    body: "ms_dao.body",
    author: "ms_dao.name",
    role: "ms_dao.role",
    imageUrl: "/images/dao-thai.jpg",
    rating: 5,
  },
  {
    title: "mr_sang.title",
    body: "mr_sang.body",
    author: "mr_sang.name",
    role: "mr_sang.role",
    imageUrl: "/images/sang-trieu.jpg",
    rating: 5,
  },
  // {
  //   title: "mr_tuan.title",
  //   body: "mr_tuan.body",
  //   author: "mr_tuan.name",
  //   role: "mr_tuan.role",
  //   imageUrl: "/images/tuan-nguyen.jpeg",
  //   rating: 5,
  // },
  // {
  //   title: "ms_thoa.title",
  //   body: "ms_thoa.body",
  //   author: "ms_thoa.name",
  //   role: "ms_thoa.role",
  //   imageUrl: "/images/thoa-luong.jpg",
  //   rating: 5,
  // },
  {
    title: "mr_kiet.title",
    body: "mr_kiet.body",
    author: "mr_kiet.name",
    role: "mr_kiet.role",
    imageUrl: "/images/kiet-trac.jpeg",
    rating: 5,
  },
  {
    title: "ms_hien.title",
    body: "ms_hien.body",
    author: "ms_hien.name",
    role: "ms_hien.role",
    imageUrl: "/images/hien-trinh.jpeg",
    rating: 5,
  },
  {
    title: "mr_linh.title",
    body: "mr_linh.body",
    author: "mr_linh.name",
    role: "mr_linh.role",
    imageUrl: "/images/linh-hap.jpg",
    rating: 5,
  },
  {
    title: "ms_tham.title",
    body: "ms_tham.body",
    author: "ms_tham.name",
    role: "ms_tham.role",
    imageUrl: "/images/tham-le.jpg",
    rating: 5,
  },
];

function StarRating({ rating }: { rating: Review["rating"] }) {
  return (
    <div className="flex items-center gap-1">
      {[...Array(5).keys()].map((index) => (
        <Icons.Star key={index} className={clsx("h-5 w-5", rating > index ? "fill-[#FFB400]" : "fill-gray-300")} />
      ))}
    </div>
  );
}

function Review({
  title,
  body,
  author,
  rating,
  role,
  imageUrl,
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"figure">, keyof Review> & Review) {
  const t = useTranslations("home");
  const animationDelay = useMemo(() => {
    const possibleAnimationDelays = ["0s", "0.1s", "0.2s", "0.3s", "0.4s", "0.5s"];
    return possibleAnimationDelays[Math.floor(Math.random() * possibleAnimationDelays.length)];
  }, []);

  return (
    <figure
      className={clsx("animate-fade-in rounded-3xl bg-white p-6 opacity-0 shadow-md shadow-gray-900/5", className)}
      style={{ animationDelay }}
      {...props}
    >
      <blockquote className="text-gray-900">
        <StarRating rating={rating} />
        <p className="mt-4 text-lg/6 font-semibold before:content-['“'] after:content-['”']">{t(title)}</p>
        <p className="mt-3 text-base/7">{t(body)}</p>
      </blockquote>
      {!role && !imageUrl ? (
        <figcaption className="mt-3 text-medium font-semibold text-gray-900 before:content-['–_']">
          {t(author)}
        </figcaption>
      ) : (
        <figcaption
          className={twClass("mt-3 flex items-center gap-x-4", {
            "before:content-['–_']": !role && !imageUrl,
          })}
        >
          {imageUrl && (
            <Image width={40} height={40} alt={t(author)} src={imageUrl} className="size-10 rounded-full bg-gray-50" />
          )}
          <div>
            <div className="text-medium font-semibold text-gray-900">{t(author)}</div>
            {role && <div className="text-sm text-gray-600">{t(role)}</div>}
          </div>
        </figcaption>
      )}
    </figure>
  );
}

function splitArray<T>(array: Array<T>, numParts: number) {
  const result: Array<Array<T>> = [];
  for (let i = 0; i < array.length; i++) {
    const index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function ReviewColumn({
  reviews,
  className,
  reviewClassName,
  msPerPixel = 0,
}: {
  reviews: Array<Review>;
  className?: string;
  reviewClassName?: (reviewIndex: number) => string;
  msPerPixel?: number;
}) {
  const columnRef = useRef<React.ElementRef<"div">>(null);
  const [columnHeight, setColumnHeight] = useState(0);
  const duration = `${columnHeight * msPerPixel}ms`;

  useEffect(() => {
    if (!columnRef.current) {
      return;
    }

    const resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current?.offsetHeight ?? 0);
    });

    resizeObserver.observe(columnRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={columnRef}
      className={clsx("animate-marquee space-y-8 py-4", className)}
      style={{ "--marquee-duration": duration } as React.CSSProperties}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName?.(reviewIndex % reviews.length)}
          {...review}
        />
      ))}
    </div>
  );
}

export function ReviewGrid() {
  const containerRef = useRef<React.ElementRef<"div">>(null);
  const isInView = useInView(containerRef, { once: true, amount: 0.4 });
  const columns = splitArray(reviews, 3);
  const column1 = columns[0];
  const column2 = columns[1];
  const column3 = splitArray(columns[2], 2);

  return (
    <div
      ref={containerRef}
      className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={[...column1, ...column3.flat(), ...column2]}
            reviewClassName={(reviewIndex) =>
              clsx(
                reviewIndex >= column1.length + column3[0].length && "md:hidden",
                reviewIndex >= column1.length && "lg:hidden"
              )
            }
            msPerPixel={25}
          />
          <ReviewColumn
            reviews={[...column2, ...column3[1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex) => (reviewIndex >= column2.length ? "lg:hidden" : "")}
            msPerPixel={30}
          />
          <ReviewColumn reviews={column3.flat()} className="hidden lg:block" msPerPixel={25} />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 z-10 h-32 bg-gradient-to-b from-gray-50 to-transparent" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 z-10 h-32 bg-gradient-to-t from-gray-50 to-transparent" />
    </div>
  );
}
