import { ReactNode } from "react";

import { Footer, Header } from "@/components/organisms";
import { twClass } from "@/utils/common";

type Props = {
  children: ReactNode;
  className?: string;
};

const BasicLayout = ({ children, className }: Props) => {
  return (
    <main className={twClass("flex min-h-screen flex-col", className)}>
      <Header />
      <div className="flex flex-1 flex-col">{children}</div>
      <Footer />
    </main>
  );
};

export default BasicLayout;
