"use client";

import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger } from "@nextui-org/react";
import { useLocale, useTranslations } from "next-intl";
import React, { Key, useCallback, useMemo, useTransition } from "react";

import { Icons } from "@/components/atoms";
import { LOCALES_SWITCHER } from "@/constants/locale";
import { usePathname, useRouter } from "@/i18n/routing";

const LocaleSwitcher = () => {
  const pathname = usePathname();
  const router = useRouter();
  const t = useTranslations();
  const currentLocale = useLocale();
  const [isPending, startTransition] = useTransition();

  const selectedLang = useMemo(() => LOCALES_SWITCHER.find((item) => item.locale === currentLocale), [currentLocale]);

  /**
   * handle locale change
   * @param locale string
   */
  const handleLocaleChange = useCallback(
    (locale: Key) => {
      if (locale !== currentLocale && typeof locale === "string") {
        startTransition(() => {
          router.replace(pathname, { locale });
        });
      }
    },
    [currentLocale, pathname, router]
  );

  return (
    <Dropdown isDisabled={isPending} radius="sm">
      <DropdownTrigger>
        <Button className="!mt-0 p-0 px-4" radius="none" variant="light">
          {selectedLang?.icon &&
            React.createElement(Icons[selectedLang.icon], {
              className: "size-6",
              "aria-label": t(selectedLang.name),
            })}
        </Button>
      </DropdownTrigger>
      <DropdownMenu variant="faded" onAction={handleLocaleChange}>
        <DropdownSection className="mb-0">
          {LOCALES_SWITCHER.map((item) => (
            <DropdownItem
              key={item.locale}
              startContent={
                item.icon &&
                React.createElement(Icons[item.icon], {
                  className: "size-6",
                  "aria-label": t(item.name),
                })
              }
            >
              {t(item.name)}
            </DropdownItem>
          ))}
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LocaleSwitcher;
