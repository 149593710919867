"use client";

import { useTranslations } from "next-intl";
import { memo } from "react";

const items = [
  {
    company: "company_martaskify",
    time: "12/2024 - 04/2025",
    role: "Front-end Developer",
    description: "martaskify.description",
    teamSize: "10+",
    technologies: "React, Tailwind css, RecoilJS, Storybook, Python",
    responsibilities: ["martaskify.responsibilities1"],
  },
  {
    company: "company_nec",
    time: "10/2024 - 11/2024",
    role: "Team Leader (Trainer)",
    description: "robocon_training.description",
    teamSize: "7+",
    technologies: "C/C++",
    responsibilities: [
      "robocon_training.responsibilities1",
      "robocon_training.responsibilities2",
      "robocon_training.responsibilities3",
      "robocon_training.responsibilities4",
    ],
  },
  {
    company: "company_gss",
    time: "05/2024 - 10/2024",
    role: "Front-end Developer",
    description: "tms2.description",
    teamSize: "6+",
    technologies: "NextJS, Tailwind css, Redux, Strapi, GraphQL, PrismaJS, MySQL, NATS, Superset",
    responsibilities: ["tms2.responsibilities1"],
  },
  {
    company: "company_nec",
    time: "11/2023 - 05/2024",
    role: "Senior Developer",
    description: "cheermedal2.description",
    teamSize: "12+",
    technologies: "ReactJS, Redux, Tailwind css, Material-UI, Jest, Aws (Lambda, API Gateway, S3, Dynamodb, etc.)",
    responsibilities: ["cheermedal2.responsibilities1"],
  },
  {
    company: "company_gss",
    time: "10/2023 - 11/2023",
    role: "Front-end Developer",
    description: "tms.description",
    teamSize: "8+",
    technologies: " NextJS, Tailwind css, Redux, Strapi, GraphQL, PrismaJS, MySQL, Firebase",
    responsibilities: ["tms.responsibilities1", "tms.responsibilities2"],
  },
  {
    company: "company_gss",
    time: "06/2023 - 10/2023",
    role: "Front-end Developer",
    description: "sparkle_ai.description",
    teamSize: "6+",
    technologies: "NextJS, Tailwind css, RecoilJS, PrismaJS, MySQL, Firebase, Clerk, OpenAI Developer Platform",
    responsibilities: ["sparkle_ai.responsibilities1", "sparkle_ai.responsibilities2"],
  },
  {
    company: "company_nec",
    time: "12/2022 - 06/2023",
    role: "Front-end Developer",
    description: "cheermedal.description",
    teamSize: "11+",
    technologies: "ReactJS, Redux, Material-UI, Jest, Aws (Lambda, API Gateway, S3, Dynamodb, etc.)",
    responsibilities: ["cheermedal.responsibilities1"],
  },
  {
    company: "company_nec",
    time: "03/2022 - 12/2022",
    role: "Front-end Developer",
    description: "canas.description",
    teamSize: "10+",
    technologies: "ReactJS, Redux, Material-UI, Jest, Java Spring Boot, Mybatis, MySQL, Redis",
    responsibilities: ["canas.responsibilities1", "canas.responsibilities2"],
  },
  {
    company: "company_vitalify",
    time: "11/2021 - 01/2022",
    role: "Front-end Developer",
    description: "bzk.description",
    teamSize: "12+",
    technologies: "Node js, Angular, Firebase, Python, Google Cloud Platform",
    responsibilities: ["bzk.responsibilities1"],
  },
  {
    company: "company_gss",
    time: "06/2021 - 11/2021",
    role: "Full-stack Developer",
    description: "wifibox.description",
    teamSize: "10+",
    technologies:
      "Java spring boot 2.2, Thymeleaf, Bootstrap 4, Mybatis/JPA, MySQL, AWS S3, Shopify Developers Platform",
    responsibilities: ["wifibox.responsibilities1", "wifibox.responsibilities2"],
  },
  {
    company: "company_gss",
    time: "11/2020 - 06/2021",
    role: "Full-stack Developer",
    description: "media_trust.description",
    teamSize: "3+",
    technologies:
      "PHP (Codeigniter, CakePHP, etc.), HTML/CSS, Linux, Apache/NGINX, Bootstrap 4, MySQL, Javascript, Jquery",
    responsibilities: ["media_trust.responsibilities1", "media_trust.responsibilities2"],
  },
  {
    company: "company_gss",
    time: "05/2020 - 11/2020",
    role: "Full-stack Developer",
    description: "jot.description",
    teamSize: "6+",
    technologies: "PHP (Codeigniter 3), MySQL, Bootstrap 4, Javascript, jQuery",
    responsibilities: ["jot.responsibilities1", "jot.responsibilities2"],
  },
  {
    company: "company_gss",
    time: "09/2019 - 05/2020",
    role: "Full-stack Developer",
    description: "vams.description",
    teamSize: "9+",
    technologies: "ASP.NET Core 2.0 (C#), Dapper, Entity Framework, MS SQL Server, Bootstrap 4, Typescript, Jquery",
    responsibilities: ["vams.responsibilities1", "vams.responsibilities2"],
  },
];

const WorkExperienceSection = () => {
  const t = useTranslations("home");

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto max-w-3xl lg:text-center">
          <h2 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            {t("work_experience")}
          </h2>
          <p className="mt-6 text-lg/8 text-gray-600">{t("work_experience_description")}</p>
        </div>
        <div className="mx-auto mt-16 bg-white px-8 py-12 sm:mt-20 lg:mt-24">
          <ul role="list" className="divide-y divide-gray-200">
            {items.map((item, index) => (
              <li key={index} className="py-4">
                <div className="grid grid-cols-1 gap-2 md:grid-cols-6">
                  <div className="col-span-1 flex flex-col md:col-span-2">
                    <p className="text-lg/8 font-semibold text-gray-900">{t(item.company)}</p>
                    <p className="mt-1 text-lg/8 text-blue-600">{item.time}</p>
                  </div>
                  <div className="col-span-1 md:col-span-4">
                    <p className="text-lg/8 font-semibold text-gray-900">{item.role}</p>
                    <p className="mt-1 text-base/7 text-gray-600">{t(item.description)}</p>
                    <p className="text-base/7 text-gray-600">
                      <span className="font-semibold">{t("team_size")}:</span> {item.teamSize}
                    </p>
                    <p className="text-base/7 text-gray-600">
                      <span className="font-semibold">{t("technologies")}:</span> {item.technologies}
                    </p>
                    {item.responsibilities.length > 0 && (
                      <>
                        <p className="text-base/7 text-gray-600">
                          <span className="font-semibold">{t("responsibilities")}:</span>
                        </p>
                        <ul role="list" className="list-disc pl-6 marker:text-gray-300">
                          {item.responsibilities.map((item, index) => (
                            <li key={index} className="text-justify text-base/7 text-gray-600">
                              {t(item)}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(WorkExperienceSection);
