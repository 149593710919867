"use client";

import { Button } from "@nextui-org/react";
import Lottie from "lottie-react";
import { useTranslations } from "next-intl";
import { memo, useCallback } from "react";

import CodingAnimation from "@/resources/animation/coding.json";

const HeroSection = () => {
  const t = useTranslations();

  const handleContactClick = useCallback(() => {
    const element = document.getElementById("contactForm");
    element?.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className="mx-auto max-w-7xl px-6 pb-20 pt-24 sm:pb-24 sm:pt-32 md:pb-32 lg:pt-40">
      <div className="mx-auto grid grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-6">
        <div className="flex w-full flex-col items-start justify-center text-justify lg:col-span-2 lg:mt-0 lg:text-left">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl md:text-left xl:text-5xl xl:!leading-[66px]">
            {t("home.full_name")}
            <br />
            {t.rich("home.role", {
              important: (chunks) => <span className="text-blue-700">{chunks}</span>,
            })}
          </h1>

          <p className="mt-6 text-lg leading-8 text-gray-600">{t("home.about_me")}</p>

          <div className="mt-10 grid grid-cols-1 items-center justify-start gap-6 gap-x-6">
            <Button color="primary" radius="none" className="px-8 md:flex lg:hidden" onClick={handleContactClick}>
              {t("header.contact")}
            </Button>
            <Button
              color="primary"
              radius="none"
              variant="flat"
              className="hidden px-8 lg:flex"
              onClick={handleContactClick}
            >
              {t("home.hire_me")}
            </Button>
          </div>
        </div>
        <div className="col-span-1 mt-24 w-full rounded-2xl object-cover sm:mt-32 lg:mt-0">
          <Lottie loop={true} autoplay={true} animationData={CodingAnimation} />
        </div>
      </div>
    </div>
  );
};

export default memo(HeroSection);
